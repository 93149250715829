import { render, staticRenderFns } from "./MBDASGNR.vue?vue&type=template&id=3828987f&scoped=true&"
import script from "./MBDASGNR.vue?vue&type=script&lang=js&"
export * from "./MBDASGNR.vue?vue&type=script&lang=js&"
import style0 from "./MBDASGNR.vue?vue&type=style&index=0&id=3828987f&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3828987f",
  null
  
)

export default component.exports